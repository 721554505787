/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CategorySort {
    width: 100%;

    .FieldSelect {
        &-Clickable {
            .FieldSelect {
                &-SortSelect {
                    margin-inline-end: 0;
                    font-weight: 700;
                    font-size: 14px;
                    text-transform: uppercase;
                    pointer-events: none;

                    @include desktop {
                        display: block;
                        width: 100%;
                        padding: 11px 20px;
                        border: 1px solid var(--color-black);
                        border-radius: 25px;
                    }

                    @include mobile {
                        width: 100%;
                        padding-block: 12px;
                        text-align: center;
                    }
                }
            }
        }

        &-Select {
            @include desktop {
                position: absolute;
                top: 0;
                opacity: 0;
            }
        }

        .ChevronIcon {
            @include mobile {
                display: none;
            }
        }
    }

    .Field-LabelContainer {
        justify-content: center;
    }
}
