/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CategoryReview {
    display: flex;
    column-gap: #{fluid(25px, 40px, 375px, 810px)};
    align-items: center;
    padding: #{fluid(25px, 40px, 375px, 810px)};

    &::before {
        @include full-cover(true);

        background-color: var(--secondary-base-color);
        opacity: .45;
        z-index: -1;
    }

    @include desktop {
        max-width: 800px;
        margin-inline: auto;

        &::before {
            @include full-page-cover();
        }
    }

    &-Visual {
        flex: 0 0 auto;
        width: 33.3333%;
        max-width: 200px;

        .Image {
            display: block;
            aspect-ratio: 1;
            border-radius: 50%;

            .Image-Image {
                object-fit: cover;
            }

            @include mobile {
                margin-inline: auto;
            }
        }
    }

    &-Content {
        flex: 1 1 auto;
    }

    &-Title {
        font-size: #{fluid(15px, 26px, 375px, 810px)};
        font-weight: 700;
        text-transform: uppercase;
    }

    &-SubTitle {
        font-size: #{fluid(11px, 16px, 375px, 810px)};
        font-weight: 500;
    }

    &-Description {
        --paragraph-font-weight: 400;
        --paragraph-line-height: 1.5;

        margin-block-start: 1em;

        p {
            font-size: #{fluid(12px, 15px)};

            &:last-child {
                margin-block-end: 0;
            }
        }
    }

    .ExpandableText-Content {
        &::after {
            @include scrimGradient($default-secondary-base-color, 'to top');
        }
    }
}
