/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

@import '../../style/base/rsuite-slider';

.CategoryConfigurableAttributes {
    // ...
}

.ProductConfigurableAttributes {
    &-PriceRange {
        padding-block-end: 20px;

        &Title {
            margin-block-end: 10px;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 1.2px;
            text-transform: uppercase;

            @include desktop {
                font-size: 14px;
                letter-spacing: 1.4px;
            }
        }

        &Content {
            margin-inline: 15px;
            padding-block: 32px 15px;

            &_isDisabled {
                --rs-slider-progress: #ddd;
            }
        }

        &Reset {
            text-align: center;

            &Button {
                font-weight: 500;
                text-decoration: underline;
                text-underline-offset: .15em;
            }
        }
    }

    .rs-slider {
        &-handle {
            .rs-tooltip {
                display: none;
            }
        }

        // CUSTOM DIV

        &-tooltip {
            position: absolute;
            // inset-block-end: calc(100% + 10px);
            inset-block-start: 0;
            translate: -50%;

            --rs-slider-input-currency-offset: .2em;

            &_start {
                inset-inline-start: var(--rs-slider-input-start-left);
            }

            &_end {
                inset-inline-start: var(--rs-slider-input-end-left);
            }

            .Field {
                margin: 0;

                input {
                    min-width: 0;
                    min-height: 0;
                    width: calc(36px + 3px * var(--rs-slider-input-length, 1));
                    height: 20px;
                    padding-block: 0;
                    padding-inline: 0 0.8em;
                    font-size: 12px;
                    font-weight: 600;
                    color: #000;
                    text-align: center;
                    line-height: 22px;
                }
            }

            .currency {
                display: block;
                position: absolute;
                inset-block-start: 50%;
                inset-inline-start: calc(50% + var(--rs-slider-input-currency-offset) * var(--rs-slider-input-length, 1));
                translate: 0 -50%;
                font-size: 12px;
                font-weight: 600;
                color: #000;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type=number] {
                -moz-appearance: textfield;
            }
        }

        input[type="range"] {
            min-width: 0;
            min-height: 0;
            padding: 0;
            border: 0;
        }
    }
}
