/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.DocumentCategory {
    &-Visual {
        aspect-ratio: 160 / 195;

        .Image {
            display: block;

            &-Image {
                object-fit: cover;
            }
        }
    }

    &-Content {
        overflow: hidden;
    }

    &-Title {
        margin-block: 0.75em 0.2em;
        font-weight: 300;
        font-size: clamp(14px, calc(0.68vw + 11.47px), 22px);
        line-height: 1.3;
        text-transform: none;
    }

    &-ProductCount {
        font-weight: 500;
        font-size: clamp(11px, calc(0.25vw + 10.05px), 14px);
    }
}
