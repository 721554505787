/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CategoryFilterOverlay {
    .Field_type_checkbox {
        flex-direction: row-reverse;
        display: flex;
        justify-content: space-between;
        margin-right: 0;

        .input-control {
            margin-right: 0 !important;
        }

        .Field-CheckboxLabel {
            width: calc(100% - 30px);

            .ProductAttributeValue-SubLabel {
                float: right;
                font-size: 11px;
                font-weight: 300;
            }
        }
    }

    @include mobile {
        padding-left: 0;
        padding-right: 0;

        &.Overlay:not(.Overlay_isStatic) {
            background: rgba(0, 0, 0, 0.8);
            top: 0;
            padding-top: 15px;
            height: 100%;
            z-index: 1000;
        }

        .CategoryFilterOverlay {
            &-Wrapper {
                border-top-left-radius: 21px;
                border-top-right-radius: 21px;
            }

            &-Heading {
                padding-top: 32px;
            }

            &-CloseBtn {
                top: 10px;
            }
        }

        .ExpandableContent {
            &-Heading {
                font-size: 12px;
                letter-spacing: 1.2px;
            }

            &-Content {
                &_isContentExpanded {
                    padding-bottom: 20px;
                }

                .ProductAttributeValue-Label {
                    font-size: 14px;
                    font-weight: 300;
                }
            }
        }
    }

    @include desktop {
        transition: transform 200ms ease-in-out;
        transform: translate3d(100%, 0, 0);
        will-change: transform;
        // overflow-y: hidden;

        // Below from .Overlay
        position: fixed;
        overflow: hidden;
        max-width: 100%;
        flex-direction: row;
        // Below from .Popup
        z-index: 400;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: var(--popup-background);
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;

        &-Wrapper {
            display: grid;
            grid-template-rows: min-content auto min-content;
            height: 100%;
            overflow-y: auto;
            // Below from .Popup
            padding: 50px var(--popup-content-padding) 0;
            min-width: var(--popup-min-width);
            max-width: var(--popup-max-width);
            background-color: var(--popup-content-background);

            .ExpandableContent {
                padding-block: 0;

                &-Button {
                    padding-block: 15px;
                }

                &-Heading {
                    font-size: 14px;
                    letter-spacing: 1.4px;
                }

                .ProductAttributeValue-Label {
                    font-size: 14px;
                    font-weight: 300;
                }

                &-Content {
                    max-height: 0;
                    overflow: hidden;
                    opacity: 0;
                    margin-top: 0;

                    &_isContentExpanded {
                        opacity: 1;
                        max-height: 100%;
                        padding-bottom: 20px;
                    }
                }
            }
        }

        &_isVisible {
            transform: translate3d(0%, 0, 0);
        }
    }

    &-Heading {
        display: block;
        font-weight: 700;
        text-transform: uppercase;

        @include mobile {
            padding-block: 16px 0;
            padding-inline: 16px;
        }

        @include desktop {
            // Below from .Popup
            margin-bottom: 1.5em;
        }

        &_isContentFiltered {
            display: block;
        }
    }

    &-CloseBtn {
        // From .Popup
        position: absolute;
        inset-block-start: -4px;
        inset-inline-end: 10px;
        z-index: 5;

        .CloseIcon {
            height: 30px;
            width: 30px;
        }

        @include desktop {
            inset-block-start: 10px;
            inset-inline-end: 35px;

            .CloseIcon {
                height: 50px;
                width: 50px;
            }
        }
    }

    &-ResetSection {
        display: flex;
        flex-wrap: wrap;

        @include desktop {
            float: left;
            margin-right: 40px;
            margin-bottom: 10px;
        }
    }

    &-Buttons {
        position: sticky;
        display: flex;
        padding: 15px;
        background-color: var(--secondary-light-color);
        border-block-start: 1px solid var(--primary-divider-color);
        bottom: 0;

        .Button {
            --button-border: var(--primary-dark-color);
            --button-background: var(--primary-dark-color);
            --hollow-button-border: var(--primary-dark-color);
            --hollow-button-color: var(--primary-dark-color);
        }

        @include desktop {
            padding: 30px 0;
            background-color: var(--color-white);
        }
    }

    &-SeeResults,
    &-ResetButton {
        width: auto;
        padding: 0;

        @include desktop {
            inset-block-end: 0;
            inset-inline-start: 0;
        }

        .Button {
            font-size: 10px;
        }
    }

    &-ResetButton {
        flex: 0 1 auto;
        margin-right: 15px;

        @include desktop {
            margin-bottom: 0;
        }
    }

    &-SeeResults {
        flex: 1 1 auto;

        @include mobile {
            position: relative;
            background-color: transparent;
            border-block-start: none;
        }

        @include desktop {
            display: block;
        }

        .Button {
            white-space: nowrap;
        }
    }

    &-ButtonCount {
        display: none;
        margin-left: .3em;
        color: var(--button-color);

        &::before {
            content: '(';
        }

        &::after {
            content: ')';
        }

        &_isVisible {
            display: inline;
        }
    }

    &-AttributesDesktop {
        display: flex;
        align-items: flex-start;
        margin-bottom: 5px;
    }

    &-FilterBtn {
        @include filter-button();

        margin-top: 5px;
    }

    &-SelectedAttributes {
        --selected-attributes-border-color: var(--color-black);
        --selected-attributes-border-radius: 21px;
        --selected-attributes-background-color: var(--color-white);

        display: flex;
        flex-wrap: wrap;
        margin-right: 25px;

        .ExpandableContent {
            margin-right: 15px;
            margin-bottom: 15px;
            border: none;

            &-Heading {
                cursor: pointer;
                font-size: 12px;
                letter-spacing: 1.2px;
                font-weight: bold;
            }

            &-Button {
                padding: 8px 20px;
                border: 1px solid var(--selected-attributes-border-color);
                border-radius: var(--selected-attributes-border-radius);
                z-index: 10;

                &_isContentExpanded {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    border-bottom-color: var(--selected-attributes-background-color);
                }
            }

            &-ButtonIcon {
                margin-block: 4px;

                &Label {
                    display: none;
                }
            }

            &-Content {
                display: none;
                margin: -1px 0 0;
                overflow: hidden;
                position: absolute;
                left: 0;
                transition: height .1s ease-out;
                width: 320px;
                z-index: 9;
                padding: 20px 20px 15px;
                background-color: var(--selected-attributes-background-color);
                border: 1px solid var(--selected-attributes-border-color);
                border-radius: var(--selected-attributes-border-radius);
                border-top-left-radius: 0;

                &_isContentExpanded {
                    display: block;
                }
            }
        }
    }
}
