/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CategoryPage {
    overflow: hidden;

    &-Wrapper {
        @include mobile {
            margin-block-start: 0;
        }

        @include desktop {
            display: block;
        }
    }

    &-ProductListWrapper {
        @include mobile {
            padding: 0 16px;
        }
    }

    &-ItemsCount {
        text-align: right;

        @include mobile {
            padding: 0;
        }

        @include desktop {
            margin-right: 30px;
        }
    }

    &-QuickAccess {
        display: flex;
        gap: 15px;
        margin-block-end: 20px;
        align-items: center;

        @include desktop {
            margin-block-end: 50px;
        }

        &Title {
            text-transform: uppercase;
            letter-spacing: 1.4px;
            font-weight: bold;
            font-size: 14px;

            @include desktop {
                width: 140px;
            }
        }

        &Content {
            display: flex;
            gap: 7px;

            @include desktop {
                flex-wrap: wrap;
                width: calc(100% - 140px);
            }

            @include mobile {
                white-space: nowrap;
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;
                -ms-overflow-style: -ms-autohiding-scrollbar;
                padding-inline: 16px;
                padding-block-end: 10px;
            }
        }

        &Item {
            &Link {
                color: #606060;
                font-weight: 500;
                background: $brown-light;
                border-radius: 20px;
                transition-duration: .25s;
                transition-timing-function: ease-out;
                transition-property: all;
                display: block;

                @include desktop {
                    font-size: 14px;
                    padding: 10px 17px;
                }

                @include mobile {
                    font-size: 11px;
                    padding: 7px 12px;
                }

                &:hover {
                    background: $beige;
                }
            }
        }
    }

    &-Filter {
        @include mobile {
            justify-content: center;
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;

            .FilterIcon {
                display: none;
            }
        }

        @include desktop {
            display: inline-flex;
        }
    }

    &-Subheading {
        margin-left: 10px;
        width: 18px;
        height: 18px;
        background-color: var(--color-black);
        border-radius: 50%;
        font-size: 8px;
        font-weight: 400;
        color: var(--color-white);
        text-align: center;
        line-height: 18px;
    }

    &-MiscellaneousLayoutWrapper {
        @include mobile {
            height: 100%;
            flex-direction: row-reverse;
        }
    }

    &-Miscellaneous {
        z-index: 5;

        @include mobile {
            display: flex;
            position: relative;
            inset-block-start: auto;
            inset-inline-start: auto;
            border-block-start: 1px solid var(--primary-divider-color);
            margin-block-end: 15px;

            &::before {
                content: "";
                display: block;
                height: 100%;
                border-left: 1px solid var(--primary-divider-color);
                position: absolute;
                top: 0;
                left: 50%;
            }

            .hideOnScroll & {
                transform: none;
            }
        }

        @include desktop {
            justify-content: flex-end;
            float: right;
            padding-block: 0 20px;

            & + * {
                clear: both;
            }
        }

        > * {
            @include mobile {
                padding-inline-start: 0;
                padding-inline-end: 0;
            }
        }

        .CategoryPage-Filter {
            @include desktop {
                display: none;
            }
        }
    }

    &-LayoutWrapper {
        @include mobile {
            width: 100%;
            justify-content: center;

            > button {
                width: 100%;
                height: 100%;
            }
        }
    }

    &-Reviews {
        margin-block-end: 20px;
        margin-inline: -16px;

        @include desktop {
            margin-block-end: 30px;
            margin-inline: 0;
        }
    }
}
