/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CategoryDetails {
    @include mobile {
        display: grid;
    }

    &-Heading {
        margin-bottom: 0.4em;
    }

    &-Description {
        &:last-child {
            // Take full length if no image
            grid-column-start: 1;
            grid-column-end: 3;
        }

        [data-content-type="heading"] {
            &:first-child {
                margin-block-start: 0;
            }
        }

        @include mobile {
            padding: 0 16px;
        }
    }

    &-DescriptionFull,
    &-Expandable {
        margin-block-end: 12px;

        @include desktop {
            margin-block-end: 20px;
        }

        &TextButton {
            display: inline;
        }
    }

    [data-content-type='text'] {
        &:last-child {
            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
