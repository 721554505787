/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.FaqCategoryDetails {
    &-Footer {
        padding: 30px;
        text-align: center;
    }

    &-Content {
        border-bottom: solid 1px #e5e5e5;

        @include mobile {
            margin-top: 2rem;
            margin-left: -14px;
            margin-right: -14px;
        }

        @include desktop {
            margin-top: 6rem;
        }
    }

    &-BackLink {
        text-decoration: underline;
        font-weight: 500;
    }

    &-Header {
        @include mobile {
            display: flex;

            /* &::before {
                display: inline-block;
                content: "";
                background: url('/style/images/faq/faq-questions.svg');
                background-repeat: no-repeat;
                background-position: left center;
                width: 54px;
                height: 60px;
                background-size: 100% 100%;
                z-index: -1;
                position: absolute;
                left: 0;
            }

            &[data-category='38'] {
                &::before {
                    background: url('/style/images/faq/faq-commandes.svg');
                }
            }

            &[data-category='39'] {
                &::before {
                    background: url('/style/images/faq/faq-commandes.svg');
                }
            }

            &[data-category='40'] {
                &::before {
                    background: url('/style/images/faq/faq-transport.svg');
                }
            }

            &[data-category='43'] {
                &::before {
                    background: url('/style/images/faq/faq-compte.svg');
                }
            }

            &[data-category='44'] {
                &::before {
                    background: url('/style/images/faq/faq-retours.svg');
                }
            }

            &[data-category='46'] {
                &::before {
                    background: url('/style/images/faq/faq-magasins.svg');
                }
            }

            &[data-category='48'] {
                &::before {
                    background: url('/style/images/faq/faq-produits.svg');
                }
            } */
        }
    }

    &-Picto {
        display: inline-block;
        width: 54px;
        height: 60px;
        vertical-align: top;
        margin-right: 23px;

        &Image {
            aspect-ratio: 1;
        }
    }

    &-Count {
        font-size: 12px;
    }

    &-Name {
        @include desktop {
            text-transform: uppercase;
            font-size: 18px;
        }
    }
}

