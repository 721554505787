/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PushCategoryHomepage {
    .HomePage & {
        padding-block: #{fluid(15px, 30px)};

        &:first-child {
            padding-block-start: 0;
        }
    }

    &-Title {
        margin-block-start: 0;
        font-size: #{fluid(15px, 20px)};
        text-transform: uppercase;
        text-align: center;
    }

    &-Content {
        margin-block-start: #{fluid(20px, 45px)};
    }

    &-CategoryImage {
        aspect-ratio: 1;

        &Wrapper {
            padding: 20%;
            border: solid 1px #E4E4E4;
            overflow: hidden;
            box-shadow: none;
            border-radius: 100%;

            &::after {
                display: block;
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                border: solid 1px transparent;
                border-radius: 100%;
                transition: all .2s ease-in;
            }
        }

        .Image-Image {
            object-fit: cover;
        }
    }

    &-CategoryLink {
        &:hover {
            .PushCategoryHomepage-CategoryImageWrapper {
                &::after {
                    border: solid 1px #000;
                }
            }
        }
    }

    &-CategoryTitle {
        margin-block-start: 1.8em;
        font-size: #{fluid(11px, 14px)};
        color: var(--color-black);
        text-transform: uppercase;
        text-align: center;
    }

    .swiper-wrapper {
        justify-content: center;
    }

    .swiper-slide {
        // max-width: 193px;
    }
}
