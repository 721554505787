/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.FaqCategoryListItem {
    border: solid 1px #e5e5e5;
    padding: 10px 12px;
    margin-bottom: 1rem;

    @include desktop {
        padding-right: 0;
    }

    &_isSelected {
        background: $default-secondary-light-color;
        border-color: $default-secondary-light-color;
    }

    &-WrapperContent {
        display: flex;

        .ExpandableContent {
            border-block-start: none;
        }
    }

    &-Content {
        &:last-of-type {
            border-bottom: none;
        }

        &_isLink {
            cursor: pointer;
            text-align: start;
            display: flex;
            align-items: center;
            width: 100%;

            .ChevronIcon {
                @include desktop {
                    position: absolute;
                    right: 20px;
                }

                @include mobile {
                    display: none;
                }
            }
        }

        &.ExpandableContent {
            width: calc(100% - 77px);
        }
    }

    &-Picto {
        display: inline-block;
        width: 54px;
        height: 60px;
        vertical-align: top;
        margin-right: 23px;

        &Image {
            aspect-ratio: 1;
        }
    }

    &-HeadingWrapper {
        width: calc(100% - 112px);
    }

    &-Heading,
    &-ExpandableContentHeading {
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1.2px;

        @include mobile {
            font-size: 12px;
        }

        @include desktop {
            font-size: 13px;
        }
    }

    &-SubHeading,
    &-ExpandableContentSubHeading {
        font-weight: 300;
        line-height: 1.1;

        @include mobile {
            font-size: 12px;
        }

        @include desktop {
            font-size: 13px;
            margin-top: 4px;
        }
    }

    &-ExpandableContent {
        &Content {
            max-height: 0;
            overflow: hidden;
            opacity: 0;

            &_isContentExpanded {
                opacity: 1;
                max-height: 100%;
                margin-bottom: 1rem;

                @include desktop {
                    margin-top: 2rem;
                }

                @include mobile {
                    margin-top: 0.2rem;
                }
            }

            button {
                border-bottom: solid 1px #e5e5e5;
                padding-bottom: 11px;
                padding-top: 11px;
                text-transform: uppercase;
                font-size: 12px;
                color: $black;
                font-weight: 500;

                .ChevronIcon {
                    --toggle-button-size: 14px;
                }
            }
        }

        &Button {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            @include desktop {
                margin-top: 12px;
            }

            .ExpandableContent-ToggleIcon {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(0, -50%);

                @include desktop {
                    right: 20px;
                }
            }
        }
    }

    &-SubCategory {
        display: inline-flex;
        justify-content: space-between;
        cursor: pointer;

        @include desktop {
            width: calc(100% - 24px);
        }

        @include mobile {
            width: 100%;
        }
    }
}
